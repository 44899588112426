import React, { useState, useEffect } from 'react';
import './CreateCourse.css';
import withAdminProtection from '../withAdminProtection'; // Adjust the path as necessary

const CreateCourse = () => {
  const [courseTitle, setCourseTitle] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [courseFile, setCourseFile] = useState(null);
  const [courseClass, setCourseClass] = useState('');
  const [courseSubject, setCourseSubject] = useState('');
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    // Fetch classes when the component mounts
    //fetch('http://localhost:5000/api/classes')
    fetch('/api/classes')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  const handleClassChange = (e) => {
    const selectedClass = e.target.value;
    setCourseClass(selectedClass);

    // Fetch subjects based on the selected class
    if (selectedClass) {
      //fetch(`http://localhost:5000/api/subjects?class_id=${selectedClass}`)
      fetch(`/api/subjects?class_id=${selectedClass}`)
        .then(response => response.json())
        .then(data => setSubjects(data))
        .catch(error => console.error('Error fetching subjects:', error));
    } else {
      setSubjects([]);
    }
  };

  const handleFileChange = (e) => {
    setCourseFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('courseTitle', courseTitle);
    formData.append('courseDescription', courseDescription);
    formData.append('courseFile', courseFile);
    formData.append('courseClass', courseClass);
    formData.append('courseSubject', courseSubject);

    try {
      /*const response = await fetch('http://localhost:5000/api/courses', {
        method: 'POST',
        body: formData,
      });
      */

      const response = await fetch('/api/courses', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setMessageType('success');
        setMessage('Course created successfully');
        // Reset form fields
        setCourseTitle('');
        setCourseDescription('');
        setCourseFile(null);
        setCourseClass('');
        setCourseSubject('');
        setSubjects([]);
      } else {
        setMessageType('error');
        setMessage('Failed to create course');
      }
    } catch (error) {
      setMessageType('error');
      setMessage('Error: ' + error.message);
    }
  };

  return (
    <div className="create-course">
      <h2>Create a New Course</h2>
      {message && (
        <div className={`message ${messageType}`}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="create-course-form">
        <div className="form-group">
          <label htmlFor="courseTitle">Course Title</label>
          <input
            type="text"
            id="courseTitle"
            value={courseTitle}
            onChange={(e) => setCourseTitle(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="courseDescription">Course Description</label>
          <textarea
            id="courseDescription"
            value={courseDescription}
            onChange={(e) => setCourseDescription(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="courseFile">Course Content File</label>
          <input
            type="file"
            id="courseFile"
            onChange={handleFileChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="courseClass">Level</label>
          <select
            id="courseClass"
            value={courseClass}
            onChange={handleClassChange}
            className="form-control"
            required
          >
            <option value="">Select Level</option>
            {classes.map(cls => (
              <option key={cls.id} value={cls.id}>
                {cls.class_name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="courseSubject">Faculty</label>
          <select
            id="courseSubject"
            value={courseSubject}
            onChange={(e) => setCourseSubject(e.target.value)}
            className="form-control"
            required
            disabled={!courseClass}
          >
            <option value="">Select Faculty</option>
            {subjects.map(subject => (
              <option key={subject.id} value={subject.id}>
                {subject.subject_name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-primary">Create Course</button>
      </form>
    </div>
  );
};

export default withAdminProtection(CreateCourse);
