import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css'; // Import custom styles
import { AuthContext } from '../AuthContext';
import logo from '../images/logo.svg';

const CustomNavbar = () => {
  const [classes, setClasses] = useState([]);
  const { isAuthenticated, logout } = useContext(AuthContext); // Use AuthContext to get the authentication status
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  useEffect(() => {
    // Fetch classes when the component mounts
    //fetch('http://localhost:5000/api/classes')
    fetch('/api/classes')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  return (
    <nav className="navbar my-2 navbar-expand-lg navbar-transparent">
      <div className="container">
      <a className="navbar-brand" href="/">
        <img src={logo} alt="Wasom huru TZ logo" className="logo-image mx-1" width="30" height="30" />
        <span className="pop">Wasom huru TZ</span>
      </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="courseCategoriesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Level
              </a>
              <ul className="dropdown-menu" aria-labelledby="courseCategoriesDropdown">
                {classes.map(cls => (
                  <li key={cls.id}>
                    <a className="dropdown-item" href={`#class-${cls.id}`}>
                      {cls.class_name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/">Forumns</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="#">Donate</Link>
            </li>
          </ul>
          <ul className="navbar-nav">
            {isAuthenticated ? (
              <>
                <li className="nav-item">
                  <button onClick={handleLogout} className='nav-link btn'>Logout</button>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/chat">Chat Room</Link>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link to="/login" className='nav-link'>Login</Link>
                </li>
                <li className="nav-item">
                  <a className="btn btn-primary" href="/register">Sign Up</a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default CustomNavbar;
